<template>
  <div id="newsStoryContent">
    <p id="Text1">
      The Maelstrom forces are strong on Crux Prime, and the construction of Nexus Tower
      is in jeopardy – your help is needed! Beginning May 3 and until the Tower stands
      mounted, your mission is to donate as many bricks as Minifigurely possible. You
      can start collecting bricks… yesterday! As an extra incentive, we’ve put some great
      prizes at stake for the 20 Minifigures who donate the most bricks!
    </p>
    <br>
    <p align="center">
      <!-- pic169F51CA5E391ED006A472DB5F7459C4.jpg -->
      <img style="width; 440px; height: 261px;" src="@/assets/news-network/community-contest-nexus-jawbox-donation-1.jpg" class="rounded">
    </p>
    <br>
    Here's your mission: Start gathering loads of bricks. Stop. After May 3, talk to Nexus
    Jay in Nimbus Station. Stop. He will send you on to Kurt Tussle at the Race Place. Stop.
    Tussle stands right next to the magnificent Nexus Jawbox. Stop. You will drop your
    bricks into its churning abyss of a mouth! Stop. If you are one of the top donators, you
    will win… real bricks! Full stop.
    <br><br>
    <p align="center">
      <!-- pic9A076C2A73577ADFE04B52274BBD30DD.jpg -->
      <img style="width: 400px; height: 270px;" src="@/assets/news-network/community-contest-nexus-jawbox-donation-2.jpg" class="rounded">
    </p>
    <br><br>
    The top contributor to Nexus Tower wins:
    <br><br>
    <p>
      o&nbsp;<a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=21009&amp;LangId=2057&amp;ShipTo=US">LEGO Architecture – Farnsworth House (#21009) </a>
      <br>
      o&nbsp;<a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=5508&amp;LangId=2057&amp;ShipTo=US">1 LEGO Large Brick Box (#5508)</a>
      <br>
      o&nbsp;<a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=630&amp;LangId=2057&amp;ShipTo=US">1 LEGO Brick Separator (#630) </a>
      <br>
      o&nbsp;<a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=55000&amp;LangId=2057&amp;ShipTo=US">1 copy of LEGO® Universe Massively Multiplayer Online Game (#55000)</a>
      <br>
      o&nbsp;An in-game item exclusive to this contest: The Hood of the Mystic
      <br>
      o&nbsp;20 in-game Faction tokens.
    </p>
    <p>
      <br>
      The 2-20 top brick contributors will win buckets of bricks:</p>
      <p>o&nbsp;<a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=5508&amp;LangId=2057&amp;ShipTo=US">1 LEGO Large Brick Box (#5508)</a>
      <br>
      o&nbsp;<a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=630&amp;LangId=2057&amp;ShipTo=US">1 LEGO Brick Separator (#630) </a>
      <br>
      o&nbsp;<a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=55000&amp;LangId=2057&amp;ShipTo=US">1 copy of LEGO® Universe Massively Multiplayer Online Game (#55000)</a>
      <br>
      o&nbsp;An in-game item exclusive to this contest: The Hood of the Mystic <br>
      o&nbsp;20 in-game Faction tokens.
    </p>
    <p>
      <br>
      Please Note: This contest will end when Nexus Tower is complete.
      This could happen at any time, though we will be tracking the progress, and give you
      updates along the way. So make sure to donate early and often to hold your place at
      the top of the leader boards.
    </p>
    <p>
      Warning: When you are not donating bricks, keep a safe distance from the hungry jaws
      of the Nexus Jawbox – it has accidentally eaten more than one Minifigure hand during
      the years. (Luckily, the unlucky Minifigure in question just put on a new hand, and
      he was fine.)
    </p>
    <p>
      <br>
      <b>The Details!</b>
    </p>
    <p>If you play on the Overbuild server (US), you must donate the most bricks to the Nexus Jawbox in Nimbus Station between 12:01 AM EST Tuesday, May 3 and until the construction of Nexus Tower is finished, no later than 11:59 PM EST Wednesday, June 15. </p>
    <p>If you play on the Storm server (UK), you must donate the most bricks to the Nexus Jawbox in Nimbus Station between 00:01 GMT Tuesday, May 3 and until the construction of Nexus Tower is finished, no later than 23:59 GMT Wednesday, June 15.</p>
    <p>If you play on the Exeter server (Germany), you must donate the most bricks to the Nexus Jawbox in Nimbus Station between 00:01 CET Tuesday, May 3 and until the construction of Nexus Tower is finished, no later than 23:59 CET Wednesday, June 15.</p>
    <p>The in-game leader boards will not reflect the 20 winners, as we are collecting the 20 players who have donated the most bricks across all 3 servers. The leader boards will show the top players on that server, and we will post regular updates to the LEGO Universe News Network with information on who is leading over all.</p>
    <p>We will determine the top 20 winners, across all 3 servers, based on how many bricks you have donated– the more you give the better! </p>
    <p>The top 20 winners will get their LEGO bricks and LEGO Universe Game in the regular mail whereas the rest of the prizes will be distributed via in-game email.</p>
    <p>
      Discuss this competition on the
      <a href="https://web.archive.org/web/20110507060238/http://messageboards.lego.com/en-US/showpost.aspx?PostID=3694281">forums</a>!
      <br>
    </p>
    <p>
      <b></b>
    </p>
  </div>
</template>
